import { Link } from "@tanstack/react-router";
import { Button } from "./ui/button";

export function SideMenu({ show }: { show: boolean }) {
  if (!show) return null;
  return (
    <aside className="w-64 bg-slate-50  flex flex-col">
      <nav className="flex flex-col p-4 space-y-2 flex-1">
        <Link to="/" className="w-full">
          <Button variant="ghost" className="w-full justify-start">
            Home
          </Button>
        </Link>
        <Link to="/about" className="w-full">
          <Button variant="ghost" className="w-full justify-start">
            About
          </Button>
        </Link>
        <Link to="/users" className="w-full">
          <Button variant="ghost" className="w-full justify-start">
            Users
          </Button>
        </Link>
      </nav>
    </aside>
  );
}
