import { createFileRoute } from "@tanstack/react-router";
import { Outlet, Link } from "@tanstack/react-router";
import logoUrl from "../../assets/realitiq-connect-logo.png";
import { useState } from "react";
import { SideMenu } from "@/components/sidemenu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { BellIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/components/providers/AuthProvider";

export const Route = createFileRoute("/_authenticated/_sidebar-layout")({
  component: () => {
    const [sideMenuOpen, setSideMenuOpen] = useState(true);
    const { logout } = useAuth();

    return (
      <div>
        <header className="flex items-center justify-between p-4 bg-white border-b border-gray-200">
          <div className="flex items-center space-x-4">
            <Button
              variant="link"
              className="text-gray-600 hover:text-gray-800"
              onClick={() => setSideMenuOpen(!sideMenuOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </Button>
            <img src={logoUrl} className="h-9" alt="logo" />
          </div>

          <div className="flex items-center space-x-4">
            <Button variant="link">
              <BellIcon />
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="link"
                  className="w-8 h-8 bg-purple-600 rounded-full flex items-center justify-center"
                >
                  <span className="text-white font-semibold">LL</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <Link to="/profile">
                  <DropdownMenuLabel>Profile</DropdownMenuLabel>
                </Link>
                <Button variant={"secondary"} onClick={() => logout()}>
                  <DropdownMenuLabel>Logout</DropdownMenuLabel>
                </Button>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </header>
        <div className="flex h-screen">
          <SideMenu show={sideMenuOpen} />
          <main className="flex-1 p-4 overflow-auto">
            <Outlet />
          </main>
        </div>
      </div>
    );
  },
});
