import { Outlet } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import Logo from '@/components/ui/logo';
import { useForm } from 'react-hook-form';
import { useAuth } from '@/components/providers/AuthProvider';

export const Route = createFileRoute('/_authenticated')({
  component: Component,
});

function Component() {
  const { register, handleSubmit } = useForm();
  const { user, login } = useAuth();

  if (user) {
    return <Outlet />;
  }
  return (
    <div className="flex h-screen flex-col md:flex-row">
      {/* Left side - Login Form */}
      <div className="flex w-full flex-col justify-center p-8 md:w-1/2">
        <div className="mx-auto w-full max-w-md">
          <h1 className="mb-2 text-2xl font-bold">
            Willkommen bei Realitiq Connect
          </h1>
          <p className="mb-8 text-gray-600">
            Immobilienverwaltung & 3D-Erfassung
          </p>

          <form
            onSubmit={handleSubmit(async (data) =>
              login(data.email, data.password),
            )}
          >
            <div className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium">
                  Email
                </label>
                <input
                  type="email"
                  autoComplete="username"
                  {...register('email')}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium">
                  Password
                </label>
                <input
                  type="password"
                  autoComplete="current-password"
                  {...register('password')}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <button
                type="submit"
                className="w-full rounded-md bg-indigo-600 py-2 text-white"
              >
                Login
              </button>
            </div>
          </form>
        </div>

        <footer className="mt-12 text-center text-sm text-gray-500">
          <a href="#" className="mx-2 hover:underline">
            Impressum
          </a>
          <a href="#" className="mx-2 hover:underline">
            Datenschutz
          </a>
          <a href="#" className="mx-2 hover:underline">
            Über Uns
          </a>
        </footer>
      </div>

      {/* Right side - Image and Branding */}
      <div className="hidden flex-col items-center justify-center space-y-8 bg-[url('/login-bg.jpg')] bg-cover bg-center p-8 md:flex md:w-1/2">
        <div className="flex-0 w-2/3">
          <Logo />
        </div>

        <div className="text-center">
          <p className="px-10 text-3xl font-bold text-white">
            Immobilienverwaltung einfach, schnell & effizient
          </p>
        </div>
      </div>
    </div>
  );
}
