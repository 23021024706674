// AuthContext.tsx
import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { auth } from '../../lib/firebase';
import {
  User,
  UserCredential,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

interface AuthContextProps {
  user: User | null;
  signup: (email: string, password: string) => Promise<UserCredential>;
  login: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isCheckingAuthStatus, setIsCheckingAuthStatus] =
    useState<boolean>(false);

  // Signup function
  const signup = (email: string, password: string): Promise<UserCredential> => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  // Login function
  const login = (email: string, password: string): Promise<UserCredential> => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  // Logout function
  const logout = (): Promise<void> => {
    return signOut(auth);
  };

  // TODO: This one shoults "Reeimplement me using xstate!"
  useEffect(() => {
    // auth.setPersistence({ type: 'NONE' });
    const checkAuth = async () => {
      setIsCheckingAuthStatus(true);
      const res = await fetch(import.meta.env.VITE_API_URL + '/authenticated', {
        credentials: 'include',
      });

      if (res.status !== 200) {
        console.log('User is not authenticated');
        return;
      }

      const user = await res.json();

      setUser(user);
      setIsCheckingAuthStatus(false);
    };

    if (!isCheckingAuthStatus && !user) {
      checkAuth();
    }

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      await fetch(import.meta.env.VITE_API_URL + '/authcookie', {
        headers: { Authorization: `${await currentUser?.getIdToken()}` },
        credentials: 'include',
      });
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value: AuthContextProps = {
    user,
    signup,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
