import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

//TODO: we should move that to env, but ATM its ok in the code
const firebaseConfig = {
  apiKey: "AIzaSyABTlvmZzh-2qrMopP547rAb6tw09YKOcc",
  authDomain: "realitiq-b6e0b.firebaseapp.com",
  projectId: "realitiq-b6e0b",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
