import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

export const Route = createFileRoute("/_authenticated/_sidebar-layout/about")({
  component: () => {
    const { t } = useTranslation();

    return <div>{t("about.title")}</div>;
  },
});
