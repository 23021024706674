import { StrictMode } from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import "./index.css";
import { treaty } from "@elysiajs/eden";
import { Api } from "realtiq-api/src/routes";
import { ThemeProvider } from "@/components/providers/ThemeProvider";
import { PostHogProvider } from "posthog-js/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./i18n";
import { AuthProvider } from "./components/providers/AuthProvider";

const queryClient = new QueryClient();

const firebaseConfig = {
  apiKey: "AIzaSyABTlvmZzh-2qrMopP547rAb6tw09YKOcc",
  authDomain: "realitiq-b6e0b.firebaseapp.com",
  projectId: "realitiq-b6e0b",
  storageBucket: "realitiq-b6e0b.appspot.com",
  messagingSenderId: "269016413524",
  appId: "1:269016413524:web:82ffe9132268e43a9044c1",
  measurementId: "G-PKVJXYNDZH",
};

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultPreloadStaleTime: 0,
  context: {
    queryClient,
    treaty: treaty<Api>(import.meta.env.VITE_API_URL, {
      fetch: { credentials: "include" },
      async fetcher(url, options) {
        const res = await fetch(url, options);

        if (!res.ok) {
          throw new Error(`${res.statusText} (${res.status})`);
        }

        return res;
      },
    }),
  },
});

Sentry.init({
  dsn: "https://0e01ef06ff7770d7df39c2fad9b4de74@o4507968654540800.ingest.de.sentry.io/4507968657227856",
  integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ThemeProvider>
        <AuthProvider>
          <PostHogProvider
            apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
            options={{
              api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
              advanced_disable_feature_flags: true,
            }}
          >
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </PostHogProvider>
        </AuthProvider>
      </ThemeProvider>
    </StrictMode>,
  );
}
